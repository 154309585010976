export const config = {
    serverRoute: "https://test-prueba-production.up.railway.app"
    //serverRoute: "http://localhost:3001"
 }
 
 export const SocketRoute = {
    serverRoute: "https://railway.grupo-hoteles.com"
    //serverRoute: "http://localhost:3001"
 }
 
 export const CLoubedsRoute ={
   serverRoute: "https://railway.grupo-hoteles.com"
   //serverRoute:"http://localhost:3001"
}

export const Environment ={
   propertyID:"315193",
   Token:"cbat_pUH4CxoVw7IjhL1G0kMDmcaMrRmC8ziW"
 }